<template>
  <section>
    <div class="richtext">
      <h2><strong>Introduction Of Manual Metering Pump</strong></h2>
      <p><br /></p>
      <p>
        The flow rate of the manual metering pump is constant and can be
        manually adjusted. The flow rate is adjusted according to the external
        analog signal, digital pulse signal (such as pulse signal from water
        meter) and RS485 communication protocol.&nbsp;<br />
      </p>
      <p><br /></p>
      <h2>
        <strong><br /></strong>
      </h2>
      <h2>
        <strong>Performance Advantages Of NEWDOSE Manual Metering Pump</strong>
      </h2>
      <p><br /></p>
      <h3>
        <strong
          >NEWDOSE Electromagnetic Pump Has Strong Compatibility&nbsp;</strong
        >
      </h3>
      <p>
        The manual metering pump head is the almost standard configuration with
        all industries and pumps. Chemicals used in sewage treatment and
        drinking water are compatible.&nbsp;
      </p>
      <p>
        The use of a ceramic ball valve improves the reliability of dosing and
        the chemical compatibility of the whole liquid contact end.&nbsp;
      </p>
      <p>It has super compatibility.&nbsp;</p>
      <p><br /></p>
      <h3><strong>Reliable Performance Of Manual Metering Pump</strong></h3>
      <p>
        The advanced design concept and manufacturing processes create a long
        service life.
      </p>
      <p>
        It is made of pure PTFE material, which is compatible with most
        chemicals.&nbsp;
      </p>
      <p>The service life is more than 5 years.&nbsp;</p>
      <p>It is no longer necessary to replace the diaphragm regularly.&nbsp;</p>
      <p>
        It reduces the workload of maintenance and it has super
        compatibility.&nbsp;
      </p>
      <p><br /></p>
      <h3>
        <strong
          >Manual Metering Pump NEWDOSE Electromagnetic Pump Has Strong Dosing
          Stability&nbsp;</strong
        >
      </h3>
      <p>It is in the voltage range of 100 to 240 volts&nbsp;</p>
      <p>
        Depends on the working environment, the pump in the coil can work
        stably. The rated voltage can be used to start the pump, which saves
        electricity.&nbsp;
      </p>
      <p>
        It has stable dosing. It is not affected by voltage fluctuations, so the
        working efficiency of the pump is improved.&nbsp;
      </p>
      <p>It reduces inventory pressure.&nbsp;</p>
      <p><br /></p>
      <h3><strong>Program Settings Are Intuitively Visible&nbsp;</strong></h3>
      <p>
        The program menu adopts the digital display mold, and the value is the
        current working stroke frequency.&nbsp;
      </p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Manual Dosing",
}
</script>